import React from "react";
import theme from "theme";
import { Theme, Text, Strong, Box, Section } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
import * as Components from "components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"faq"} />
		<Helmet>
			<title>
				Radiant Auto Spa
			</title>
			<meta name={"description"} content={"Бездоганна чистота, бездоганний догляд - Radiant Auto Spa!"} />
			<meta property={"og:title"} content={"Radiant Auto Spa"} />
			<meta property={"og:description"} content={"Бездоганна чистота, бездоганний догляд - Radiant Auto Spa!"} />
			<meta property={"og:image"} content={"https://uploads.quarkly.io/6698e1de2fc7070018b9f8e0/images/2-3.jpg?v=2024-07-18T09:42:36.086Z"} />
			<link rel={"shortcut icon"} href={"https://uploads.quarkly.io/6698e1de2fc7070018b9f8e0/images/2-3.jpg?v=2024-07-18T09:42:36.086Z"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://uploads.quarkly.io/6698e1de2fc7070018b9f8e0/images/2-3.jpg?v=2024-07-18T09:42:36.086Z"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://uploads.quarkly.io/6698e1de2fc7070018b9f8e0/images/2-3.jpg?v=2024-07-18T09:42:36.086Z"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://uploads.quarkly.io/6698e1de2fc7070018b9f8e0/images/2-3.jpg?v=2024-07-18T09:42:36.086Z"} />
			<link rel={"apple-touch-startup-image"} href={"https://uploads.quarkly.io/6698e1de2fc7070018b9f8e0/images/2-3.jpg?v=2024-07-18T09:42:36.086Z"} />
			<meta name={"msapplication-TileImage"} content={"https://uploads.quarkly.io/6698e1de2fc7070018b9f8e0/images/2-3.jpg?v=2024-07-18T09:42:36.086Z"} />
		</Helmet>
		<Components.Header2>
			<Override slot="text" />
			<Override slot="link2" />
		</Components.Header2>
		<Section padding="100px 0 100px 0" background="--color-darkL2" quarkly-title="FAQ-7">
			<Text margin="0px 0px 2rem 0px" font="normal 600 42px/1.2 --fontFamily-sans" color="--light">
				Поширені запитання
			</Text>
			<Box
				min-width="100px"
				min-height="100px"
				display="grid"
				grid-template-columns="repeat(3, 1fr)"
				grid-gap="50px 50px"
				lg-grid-template-columns="repeat(2, 1fr)"
				md-grid-template-columns="1fr"
				sm-grid-gap="35px 0"
			>
				<Box min-width="100px" min-height="100px">
					<Text margin="0px 0px 15px 0px" font="normal 500 25px/1.2 --fontFamily-sans" color="--light">
						<Strong>
							1. Які послуги надає Radiant Auto Spa?
						</Strong>
						{"\n"}
					</Text>
					<Text margin="0px 0px 0px 0px" font="normal 300 16px/1.5 --fontFamily-sansHelvetica" color="--light">
						Radiant Auto Spa пропонує широкий спектр послуг, включаючи:
						<br />
						Мийку автомобілів вручну
						<br />
						Полірування кузова
						<br />
						Хімчистку салону
						<br />
						Покриття кузова керамікою
						<br />
						Віск для додаткового блиску
					</Text>
				</Box>
				<Box min-width="100px" min-height="100px">
					<Text margin="0px 0px 15px 0px" font="normal 500 25px/1.2 --fontFamily-sans" color="--light">
						<Strong>
							2. Чи є у Radiant Auto Spa програма лояльності?
						</Strong>
						{"\n"}
					</Text>
					<Text margin="0px 0px 0px 0px" font="normal 300 16px/1.5 --fontFamily-sansHelvetica" color="--light">
						Так, ми маємо програму лояльності, що дозволяє накопичувати бонусні бали при кожному відвідуванні. Деталі можна дізнатися на нашому сайті або у наших працівників.
					</Text>
				</Box>
				<Box min-width="100px" min-height="100px">
					<Text margin="0px 0px 15px 0px" font="normal 500 25px/1.2 --fontFamily-sans" color="--light">
						<Strong>
							3. Чи можна замовити послуги Radiant Auto Spa онлайн?
						</Strong>
						{"\n"}
					</Text>
					<Text margin="0px 0px 0px 0px" font="normal 300 16px/1.5 --fontFamily-sansHelvetica" color="--light">
						Так, ви можете попередньо записатися на мийку або інші послуги через наш веб-сайт. Ми також приймаємо телефонні дзвінки для запису.
					</Text>
				</Box>
				<Box min-width="100px" min-height="100px">
					<Text margin="0px 0px 15px 0px" font="normal 500 25px/1.2 --fontFamily-sans" color="--light">
						<Strong>
							4. Які години роботи Radiant Auto Spa?
						</Strong>
						{"\n"}
					</Text>
					<Text margin="0px 0px 0px 0px" font="normal 300 16px/1.5 --fontFamily-sansHelvetica" color="--light">
						Ми відкриті щодня з 10:00 до 21:00.
					</Text>
				</Box>
				<Box min-width="100px" min-height="100px">
					<Text margin="0px 0px 15px 0px" font="normal 500 25px/1.2 --fontFamily-sans" color="--light">
						<Strong>
							5. Які методи оплати приймаються в Radiant Auto Spa?
						</Strong>
						{"\n"}
					</Text>
					<Text margin="0px 0px 0px 0px" font="normal 300 16px/1.5 --fontFamily-sansHelvetica" color="--light">
						Ми приймаємо готівку та основні кредитні та дебетові картки, включаючи Visa, MasterCard та American Express.
					</Text>
				</Box>
				<Box min-width="100px" min-height="100px">
					<Text margin="0px 0px 15px 0px" font="normal 500 25px/1.2 --fontFamily-sans" color="--light">
						<Strong>
							6. Які гарантії надає Radiant Auto Spa на свої послуги?
						</Strong>
						{"\n"}
					</Text>
					<Text margin="0px 0px 0px 0px" font="normal 300 16px/1.5 --fontFamily-sansHelvetica" color="--light">
						Ми надаємо гарантію задоволення для всіх наших клієнтів. Якщо ви не повністю задоволені результатом, будь ласка, зверніться до наших менеджерів, і ми зробимо все можливе для виправлення ситуації.
					</Text>
				</Box>
			</Box>
		</Section>
		<Components.Header2 />
		<Components.Footer />
		<RawHtml>
			<style place={"endOfHead"} rawKey={"66028e06867c86001f1d8b21"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});